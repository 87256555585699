.sidebarTitle {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2.3px;
}

.breadcrumb {
  padding: 12px 16px;
  background: #f8f8f8;
  margin: 12px 16px;
  display: block;
}

.breadcrumbItem {
  padding: 6px;
}

.breadcrumbItem a {
  color: #20a8d8;
}

.breadcrumbSpan {
  color: #73818f;
}
